import dayjs from "dayjs";
import type { FC } from "react";
import { Suspense } from "react";

import { VStack } from "@chakra-ui/react";

import {
  type HeadersFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import EventCalendarsQuery from "~/api/query/EventCalendarsQuery";
import GetHomeEventsQuery from "~/api/query/GetHomeEventsQuery";
import HomepageSettingsQuery from "~/api/query/HomePageSettingsQuery";
import HomeHotKeywordList from "~/components/home/HomeHotKeywordList";
import HomeSectionsRenderer from "~/components/home/sections/HomeSectionsRenderer";
import TopSection from "~/components/home/topSection";
import { getMetaContent } from "~/components/meta";
import { getRegionLocaleIsValid } from "~/libs/i18n";
import {
  generateFetchAndProcessData,
  generateId,
  returnUniqueItems,
} from "~/utils/commons";
import { loaderJson } from "~/utils/json";
import { serverAddEventViewCountImpression } from "~/utils/viewCount";
import type {
  EventCalendar_region_Input,
  Event_region_Input,
  HomepageSetting_region_Input,
  LocaleInputType,
} from "~/zeus";
import { FallbackLocaleInputType } from "~/zeus";

export const meta: MetaFunction<typeof loader> = ({ params }) => {
  // Get locale from params
  const locale = params?.locale;

  // Create translation mapping based on locale
  const translations = {
    main: {
      zh: "香港好去處 演唱會",
      en: "Hong Kong Hotspots & Concerts",
      cn: "香港好去处 演唱会"
    }
  };

  // Get translation for current locale
  const mainTranslation = translations.main[locale as keyof typeof translations.main] || "Editor's Choice";

  // Build title
  const pageTitle = `Timable ${mainTranslation}`;

  // Build description
  const metaDescription = "好去處 展覽 演唱會 舞台劇 親子 餐廳 拍拖 本地遊 打卡 景點 商場 週末";

  return getMetaContent([
    {
      title: pageTitle,
    },
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: pageTitle,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:locale",
      content: params?.locale,
    },
    {
      property: "og:type",
      content: "website",
    },
  ]);
};

export const headers: HeadersFunction = () => {
  return {
    "Cache-Control": `public, max-age=${process.env.REMIX_PUBLIC_CACHE_CONTROL_TIME ?? 300}`,
  };
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  // Let root.tsx loader to redirect to correct path
  if (!getRegionLocaleIsValid(request.url))
    return loaderJson({
      homePageSettings: null,
      homeTrendList: null,
      homePreferenceList: null,
      homeDayList: null,
    });

  const locale = params.locale as LocaleInputType;
  const region = params.region as
    | EventCalendar_region_Input
    | HomepageSetting_region_Input
    | Event_region_Input;

  const listVar = {
    locale: locale as LocaleInputType,
    fallbackLocale: FallbackLocaleInputType.zh,
    where: {
      status: {
        equals: "published",
      },
      sections__endDatetime: {
        greater_than_equal: dayjs().endOf("day").toISOString(),
      },
      region: {
        equals: region as Event_region_Input,
      },
    },
  };

  const homeTrendList = await generateFetchAndProcessData(
    {
      query: GetHomeEventsQuery,
      variables: {
        ...listVar,
        limit: 6,
        sort: "-views",
      },
    },
    request,
    "GetEvents",
    params.locale as LocaleInputType
  );

  const homePreferenceList = await generateFetchAndProcessData(
    {
      query: GetHomeEventsQuery,
      variables: {
        ...listVar,
        limit: 6,
      },
    },
    request,
    "GetEvents",
    params.locale as LocaleInputType
  );

  const homeDayList = await generateFetchAndProcessData(
    {
      query: EventCalendarsQuery,
      variables: {
        limit: 7,
        sort: "date",
        locale: locale as LocaleInputType,
        where: {
          region: {
            equals: region as EventCalendar_region_Input,
          },
          AND: [
            {
              date: {
                greater_than_equal: dayjs().startOf("day").toISOString(),
              },
            },
          ],
        },
      },
    },
    request,
    "EventCalendars",
    params.locale as LocaleInputType
  );

  let homePageSettings = await generateFetchAndProcessData(
    {
      query: HomepageSettingsQuery,
      variables: {
        locale,
        where: {
          region: {
            equals: region as HomepageSetting_region_Input,
          },
        },
      },
    },
    request,
    "HomepageSettings",
    params.locale as LocaleInputType
  );

  const data = {
    homePageSettings: homePageSettings?.docs?.[0],
    homeTrendList,
    homePreferenceList,
    homeDayList,
  };

  const targetTypeNames = ["EventRankingBlock"];
  const getAllEvents = (data: any) => {
    return data?.sections?.reduce((acc: any[], section: any) => {
      if (targetTypeNames?.includes(section?.__typename)) {
        if (section.__typename === "EventRankingBlock") {
          const eventRankingBlock = section;
          ((eventRankingBlock?.ranking?.list as any[]) || [])?.forEach(
            (rankingItem: any) => {
              acc.push(rankingItem?.item);
            }
          );
        }
      }
      return acc;
    }, []);
  };

  const allEvents = returnUniqueItems(
    [
      ...getAllEvents(data.homePageSettings),
      ...(data?.homeTrendList?.docs || []),
      ...(data?.homeDayList?.docs?.map((d: any) => d?.item) || []),
      ...(data?.homePreferenceList?.docs || []),
    ],
    "id"
  );

  const getAllEventIds = allEvents
    ?.filter((events) => events)
    ?.map((evt) => evt?.id && generateId(evt?.id));

  const eventIds = getAllEventIds && getAllEventIds.toString();

  eventIds &&
    serverAddEventViewCountImpression(
      params,
      request,
      eventIds!,
      "homePageImpression"
    );

  return loaderJson(data);
};

const Index: FC = () => {
  return (
    <VStack spacing={{ base: 8, lg: 12 }} overflow={"hidden"}>
      <Suspense>
        <TopSection />
        <HomeHotKeywordList />
        <HomeSectionsRenderer />
      </Suspense>
    </VStack>
  );
};

export default Index;
